import React from 'react'
import DP from "../../../assets/images/DP.png"
import ReviewCard from './Cards/ReviewCard'
const LastSection = () => {
  return (
    <div className='text-white pt-24 pb-48 bg-gradient-to-tr from-[#2B2B45] to-[#130515] '>
        <h1 className='text-center font-bold text-[49px]'>Hear from our users</h1>
        <div className='flex gap-10 mx-10 mt-10'>
        <ReviewCard image={DP}/>
        <ReviewCard image={DP}/>
        </div>
        
    </div>
  )
}

export default LastSection