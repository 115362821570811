import React from 'react';
import image1 from "../../assets/images/Group 7380.png";
import image2 from "../../assets/images/Group 7375.png";
import image3 from "../../assets/images/Group 7376.png";
import image4 from "../../assets/images/Group 7377.png";
import image5 from "../../assets/images/Group 7378.png";
import image6 from "../../assets/images/Group 7379.png";

import backgroundImage from "../../assets/images/BackgroundImageStory.png";
import GuideTrack from './GuideTrack';
import Header from '../Header';
import DownloadLink from '../DownloadLink';
import Footer from '../Footer';

const Guided = () => {
    const data = [
        {
            image: image1,
            title: "Mindfulness",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        },
        {
            image: image2,
            title: "Compassion",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        },
        {
            image: image3,
            title: "Chosen word",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        },
        {
            image: image4,
            title: "Grateful",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        },
        {
            image: image5,
            title: "Mornings",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        },
        {
            image: image6,
            title: "Love",
            About: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Experience stress release and anxiety relief with binaural beats and brain."
        }
    ];
     
    const text="Guided Meditations"
    // Calculate the height dynamically based on the number of data items
    const itemHeight = 450; // approximate height of each GuideTrack (including margin)
    const height = data.length * itemHeight; // total height

    return (
        <div className='relative'>
            {/* Background section with dynamic height */}
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full relative'
                style={{ minHeight: `${height}px` }} // Apply calculated height
            >
                <Header image={backgroundImage} />
            </div>
        
            {/* Content section */}
            <div className='absolute top-0 left-0 w-full flex flex-col items-center z-50 mt-16'>
                {data.map((item, index) => (
                    <GuideTrack 
                        key={index} 
                        image={item.image} 
                        title={item.title} 
                        About={item.About} 
                    />
                ))}
            
            </div>
            <Footer/>
        </div>
    );
};

export default Guided;
