import React, { useState } from 'react';
import BackgroundImage from "../../assets/images/BackgroundImageBrainWaves.png";
import image1 from "../../assets/images/Group 7199.png";
import image2 from "../../assets/images/Group 7200 (1).png";
import image3 from "../../assets/images/Group 7200.png";
import image4 from "../../assets/images/Group 7201.png";
import image5 from "../../assets/images/Group 7202.png";
import image6 from '../../assets/images/Group 7203.png';
import image7 from "../../assets/images/Group 7211.png";
import image8 from "../../assets/images/Group 7212.png";
import image9 from "../../assets/images/Group 7213.png";
import image10 from "../../assets/images/Group 7349 (1).png";
import newImage1 from "../../assets/images/Group 7273.png";
import newImage2 from "../../assets/images/Group 7268.png";
import newImage3 from "../../assets/images/Group 7272.png";
import newImage4 from "../../assets/images/Group 7271.png";
import newImage5 from "../../assets/images/Group 7349 (2).png";
import Header from '../Header';
import DownloadLink from '../DownloadLink';
import Footer from '../Footer';

const BrainWaves = () => {
    const [isButton1, setButton1] = useState(true);
    const [isButton2, setButton2] = useState(false);
    const text1="Solfeggio Frequencies"
    const text2="Binaural Beats"
    const customStyle = {
        backdropFilter: 'blur(7px)',
    };

    const data1 = [
        { album: image1 },
        { album: image2 },
        { album: image3 },
        { album: image4 },
        { album: image5 },
    ];

    const data2 = [
        { album: image6 },
        { album: image7 },
        { album: image8 },
        { album: image9 },
        { album: image10 },
    ];

    const data3 = [
        { album: newImage1, name: "Pain Relief", title: "Acts as a pain killer, releases endorphins" },
        { album: newImage2, name: "Pain Relief", title: "Acts as a pain killer, releases endorphins" },
        { album: newImage3, name: "Pain Relief", title: "Acts as a pain killer, releases endorphins" },
        { album: newImage4, name: "Pain Relief", title: "Acts as a pain killer, releases endorphins" },
        { album: newImage5, name: "Pain Relief", title: "Acts as a pain killer, releases endorphins" },
    ];

    // Calculate dynamic height based on the content
    const baseHeight = 400; // Base height for the header and buttons
    const contentHeight =400; // Different height for different content
    const totalHeight = baseHeight + contentHeight;

    return (
        <div className=''>
            {/* Background with dynamic height */}
            <div
                className='bg-gradient-to-tr from-black to-[#342C43] w-full  z-10 relative'
                style={{ minHeight: `${totalHeight}px` }} // Apply dynamic height
            >
                <Header image={BackgroundImage} />
            </div>

            {/* Buttons */}
            <div className='flex text-white gap-10 z-50 -mt-[750px] justify-center  '>
                <button
                    className={`${isButton1 ? " bg-gradient-to-br from-white/60 to-[#474055]" : ""} border border-gray-600 py-3 px-7 rounded-xl mt-10 cursor-pointer z-50`}
                    onClick={() => {
                        setButton1(true);
                        setButton2(false);
                    }}
                    style={customStyle}
                >
                    Solfeggio Frequencies
                </button>
                <button
                    className={`${isButton2 ? "bg-gradient-to-br from-white/60 to-[#474055]" : ""} border border-gray-600 py-3 px-10 rounded-xl mt-10 cursor-pointer z-50`}
                    onClick={() => {
                        setButton2(true);
                        setButton1(false);
                    }}
                    style={customStyle}
                >
                    Binaural Beats
                </button>
            </div>

            {/* Content for Button 1 */}
            {isButton1 && (
                <div className='absolute z-50 w-full' >
                    <h1 className='text-white mt-6 font-bold mx-[70px]'>Mental Well Being</h1>
                    <div className='flex mt-5 justify-between mx-[70px] '>
                        {data1.map((i, index) => (
                            <img key={index} src={i.album} width={165} className='cursor-pointer' alt="album" />
                        ))}
                    </div>
                    <h1 className='text-white mt-20 font-bold mx-[70px]'>Physical Well Being</h1>
                    <div className='flex mx-[70px] mt-5 justify-between '>
                        {data2.map((i, index) => (
                            <img key={index} src={i.album} width={165} className='cursor-pointer' alt="album" />
                        ))}

                    </div>
                    
                </div>
            )}

            {/* Content for Button 2 */}
            {isButton2 && (
                <div className='text-white absolute z-50 w-full  '>
                    <h1 className='mt-6 font-bold ml-[75px]'>Delta Waves</h1>
                    <div className='flex mt-5 justify-between mx-[75px]'>
                        {data3.map((i, index) => (
                            <div key={index} className='w-44'>
                                <img src={i.album} width={165} className='cursor-pointer' alt="album" />
                                <h1 className='text-center font-bold'>{i.name}</h1>
                                <h3 className='text-center text-sm'>{i.title}</h3>
                            </div>
                        ))}
                    </div>
                    <h1 className='text-white mt-10 font-bold  mx-[75px]'>Theta Waves</h1>
                    <div className='flex gap-5 mt-5 justify-between mx-[75px]'>
                        {data3.map((i, index) => (
                            <div key={index} className='w-44'>
                                <img src={i.album} width={165} className='cursor-pointer' alt="album" />
                                <h1 className='text-center font-bold'>{i.name}</h1>
                                <h3 className='text-center text-sm'>{i.title}</h3>
                            </div>
                        ))}
                        
                    </div>
                   
                </div>
            )}
            <div className='absolute mt-[650px] w-full '>
            <Footer/>
            
            </div>

        </div>
    );
};

export default BrainWaves;
