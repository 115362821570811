import React from 'react';
import yoga from "../../../assets/images/BolgImage1.png";
import love from "../../../assets/images/BlogImage2.png";
import Slider from './Slider/Slider';

const EightSection = () => {
    const blogData = [
        { image: yoga, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: yoga, des: { category:"Mental Health",title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
        { image: love, des: {category:"Mental Health", title: "How brainwaves are effective?", des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.", pra: "#Brainwaves  #Focusbetter  #Concentration" }},
    ];

    return (
        <div className=" text-white pt-[300px] bg-gradient-to-br from-[#2A2B45] to-[#130515] ">
            <h1 className="text-center text-[49px] font-bold">Our Blogs</h1>
            <Slider items={blogData} />
            
        </div>
    );
};

export default EightSection;
