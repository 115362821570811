import React from 'react';
import logo from "../assets/images/Group 123 (1).png";
import Name from "../assets/images/Frame 33643.png";
import app1 from "../assets/images/Apple (1).png";
import app2 from "../assets/images/Apple (2).png";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate=useNavigate();
  const handleScrollClick = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
};
  return (
    <div className='flex justify-between items-center bg-black text-white px-6 py-8'>
      
      <div className='flex gap-5 '>
        <img src={logo} className='w-[82px] h-[130px]' alt="Logo" />
        <div className=' flex flex-col text-white/50 space-y-2 text-[12.6px] font-semibold cursor-pointer'>
          <div>About Us</div>
          <div onClick={()=>{
handleScrollClick('/blog');
          }}>Blogs</div>
          <div onClick={()=>{
handleScrollClick('/privacy');
          }}>Privacy & Policy</div>
          <div>Terms & Condition</div>
          <div>Contact Us</div>
        </div>
      </div>

      
      <div className='flex flex-col items-center'>
        <h1 className='text-white/50 text-[20.699px] font-semibold text-center'>To Access All Our Content Download Our App</h1>
        <img src={Name} className='w-[447.3px] mt-2' alt="App Name" />
      </div>

      
      <div className='flex flex-col items-center space-y-2'>
        <img src={app2} className='w-[198px]' alt="App 1" />
        <img src={app1} className='w-[198px]' alt="App 2" />
      </div>
    </div>
  );
};

export default Footer;
