import React from 'react';
import MobilePhone from "../../../assets/images/Group 7367.png";
import HoverImage from "../../../assets/images/Group 7366.png"
import BackgroundImage2 from "../../../assets/images/BackgroundImage4.png"
import BackgroundImage1 from "../../../assets/images/backgroundImage5.png"
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const SeventhSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };

    return (
        <div className=' text-white pt-[300px] mt-[60px] relative bg-gradient-to-br from-[#2A2B45] to-[#2A2B45] '>
        {/* <img src={BackgroundImage2} className='absolute h-[112.35px] w-full' alt="Background" /> */}
        <div className='bg-container overflow-hidden absolute'>
        <div className="scroll-background-left">
          <img src={BackgroundImage2} className='bg1' alt="Background 1" />
          <img src={BackgroundImage2} className='bg1' alt="Background 1" />
        </div>
      </div>
      <div className='flex ml-20 mr-14 image-container  '>
        <img
          src={MobilePhone}
          className='w-[450px] h-[740px] default-image z-50 -mt-44 '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[450px] h-[740px] hovered-image z-50 -mt-44  '
          alt="Mobile Phone Hover"
        />
    <div className='z-20  ml-[200px] shadow-2xl shadow-black h-[303px] mt-20 rounded-lg  py-14  text-center ' style={customStyle}>
        <h1 className='text-[33.6px] font-bold mb-[20px] ml-72'>Unlock Mental Well-Being with Brainwaves</h1>
        <h3 className='mb-7 text-[17px] ml-96 mr-28 '>Experience stress release and anxiety relief with binaural beats and brain frequency therapy. Find different waves for best mental well-being, body stress release, and better physical wellbeing.</h3>
        <div className='ml-80'>
        
<ExploreButton path={'/brainwaves'} />
        </div>

    </div>
           
        </div>
        {/* <img src={BackgroundImage1} className=' -mt-8 ' alt="Background" /> */}
        <div className='bg-container overflow-hidden absolute -mt-8'>
        <div className="scroll-background-right">
          <img src={BackgroundImage1} className='bg2' alt="Background 1" />
          <img src={BackgroundImage1} className='bg2' alt="Background 1" />
        </div>
        </div>
    </div>
    );
};

export default SeventhSection;
