import React from 'react';
import MobilePhone from "../../../assets/images/Reactangle 8 (2).png";
import HoverImage from "../../../assets/images/Reactangle 8 (1).png"
import BackgroundImage3 from "../../../assets/images/BackgroundImage3.png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';
const SixthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate=useNavigate();

    return (
        <div className=' text-white pt-[300px] relative'>
            <img src={BackgroundImage3} className='absolute h-[353.535px] w-full ' alt="Background" />
            <div className='flex mr-[500px] ml-16 image-container'>
                <div className=' shadow-2xl shadow-black mt-10 h-[255px]  rounded-lg pl-12 pr-[60px] pt-6 text-center' style={customStyle}>
                    <h1 className='text-[33.6px] font-bold mb-[14px]'>Stress Relief with Online FM Radio</h1>
                    <h3 className='mb-[35px] text-[17px] mx-36 '>Enjoy Bollywood & Hollywood online radio and FM stations for ultimate relaxation. Tune in and unwind anywhere, anytime.
                    </h3>
                 
<ExploreButton path={'/radio'} />
                </div>
                {/* <img src={MobilePhone3} className='w-[314.3px] h-[596.4px] absolute left-[900px] -mt-36 z-20' alt="Mobile Phone" /> */}
                <div className='-mt-40 z-40 -ml-[95px]'>
<img
          src={MobilePhone}
          className='w-[390px] h-[740px] default-image z-50 -mt-10 '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[390px] h-[740px] hovered-image z-50 -mt-10  '
          alt="Mobile Phone Hover"
        />
</div>
            </div>
        </div>
    );
};

export default SixthSection;
