import React, { useState } from 'react';
import yoga from "../../assets/images/BolgImage1.png";
import love from "../../assets/images/BlogImage2.png";
import BlogCard from '../Home/Sections/Cards/BlogCard';
import Header from "../Header";
import Menu from './Menu';
import Footer from '../Footer';

const Blog = () => {
  const [selectedMenu, setSelectedMenu] = useState("All");

  const blogData = [
    {
      image: yoga,
      des: {
        category: "Mental Health",
        title: "How brainwaves are effective?",
        des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.",
        pra: "#Brainwaves  #Focusbetter  #Concentration",
      },
    },
    {
      image: love,
      des: {
        category: "Mental Health",
        title: "How brainwaves are effective?",
        des: "Experience stress release and anxiety relief with binaural beats and brain frequency therapy.",
        pra: "#Brainwaves  #Focusbetter  #Concentration",
      },
    },
  ];

  const menu = ["All", "Mental health"];

  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem);
  };

  return (
    <div className="bg-gradient-to-br from-black to-[#423956] w-full  z-10 ">
      <Header />
      <div className="flex px-20 py-10 justify-center gap-8">
        {menu.map((item) => (
          <Menu
            key={item}
            item={item}
            isSelected={selectedMenu === item}
            onClick={() => handleMenuClick(item)}
          />
        ))}
      </div>
      <div className="flex  flex-wrap justify-between px-[100px] pb-20">
        {blogData.map((i, index) => (
          <BlogCard key={index} image={i.image} des={i.des} />
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default Blog;
