import React from 'react'
import Photos from "../../../assets/images/1234.png"
const FirstSection = () => {
  return (
    <div className=' text-white pb-[700px] '>
      <div className='bg-gradient-to-tr from-black to-[#5D548A] -mt-16 absolute w-full h-[550px] opacity-30 z-10'>

      </div>
      <div className='absolute z-50'>
      <h1 className='text-center font-bold text-[33.6px] pt-10 wix-madefor-text-mixed ' >Your <span className='italic'>Different</span> Needs, Our <span className='italic'>Perfect </span>Sounds! </h1>
      <h3 className='text-center text-[16.8px] pt-5 px-32 z-50 wix-madefor-text-regular '>Life can be Overwhelming. Finding the right sounds shouldn’t be. Soundscape offers Curated Playlists for Every Mood, Live Radio, and Calming Nature Sounds. Enhance Your life, Sleep Better, And Find Peace With Soundscape.</h3>
      <img src={Photos}   className='mt-10 w-full object-contain align-middle'/>
      </div>
    

    </div>
  )
}

export default FirstSection