import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import TopPlaylist from './components/Top-Playlist/TopPlaylist';
import Mood from './components/Mood/Mood';
import Stories from './components/Stories/Stories';
import { MyProvider } from './context/MyContext';
import BrainWaves from './components/Brainwaves/BrainWaves';
import Guided from './components/Guided/Guided';
import Radio from './components/Radio/Radio';
import Blog from './components/Blogs/Blog';
import BlogDes from './components/Blogs/BlogDes';
import { useEffect } from 'react';
import PrivacyPolicy from './components/PrivacyPolicy';
function App() {
const cacheImages=async(srcImages)=>{
  const promises =await srcImages.map((src)=>{
    return new Promise(function(res,rej){
      const img=new Image();
      img.src=src;
      img.onload=res();
      img.onerror=rej();
    })
  })
  await Promise.all(promises)
}
  useEffect(()=>{
    const images=[
      "./assets/images/1234.png"
    ]
    cacheImages(images);
  },[])
  return (
<div className='wix-madefor-text-bold '>
      <MyProvider >
      <Router>
        <Routes>
          
          <Route path='/' element={<Home/>}/>
          <Route path='/top-playlist' element={<TopPlaylist/>}/>
          <Route path='/mood' element={<Mood/>}/>
          <Route path='/stories' element={<Stories/>}/>
          <Route path='/brainwaves' element={<BrainWaves/>}/>
          <Route path='/guided' element={<Guided/>}/>
          <Route path='/radio' element={<Radio/>}/>
          <Route path='/blog' element={<Blog/>}/>
          <Route path='/blog-des' element={<BlogDes/>}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
        </Routes>
    </Router>
      </MyProvider>
   </div>
    
  );
}

export default App;
