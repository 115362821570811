import React, { useState } from 'react';
import MobilePhone from "../../../assets/images/HoverImage22 (2).png";
import BackgroundImage2 from "../../../assets/images/BackgroundImage2.png";
import HoverImage from "../../../assets/images/HoverImage22 (1).png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const FourthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate = useNavigate();

    return (
        <div className=' text-white pt-[300px] relative h-[750px] bg-gradient-to-tr from-black to-[#54568A]'>
            <img src={BackgroundImage2} className='absolute h-[353.535px] w-full' alt="Background" />
            <div className='flex ml-24 mr-80 image-container'>
                <div className='w-full shadow-2xl shadow-black mt-12 h-[255px] rounded-lg pl-12 pr-[60px] pt-6 text-center' style={customStyle}>
                    <h1 className='text-[33.6px] font-bold mb-[14px]'>Have a sound sleep with our Sleep Stories</h1>
                    <h3 className='mb-[35px] text-[17px]'>Feeling restless before sleep? No worries, our sleep stories got your back. Our sleep stories provide the perfect blend of stress relief and focus sleep, helping you unwind and sleep soundly.</h3>
                  
                        <ExploreButton path={'/stories'}/>
                
                </div>
<div className='-mt-40 z-40 -ml-[95px]'>
<img
          src={MobilePhone}
          className='w-[390px] h-[748px] default-image z-50 -mt-10 '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[390px] h-[748px] hovered-image z-50 -mt-10  '
          alt="Mobile Phone Hover"
        />
</div>
                
            </div>
        </div>
    );
};

export default FourthSection;
