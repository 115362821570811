import { useNavigate } from "react-router-dom";
import logo from "../assets/images/Frame 33599.png";
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { MyContext } from "../context/MyContext";

const Header = ({ image }) => {
    const location = useLocation();
    const { isStory, setStory } = useContext(MyContext);
    
    useEffect(() => {
        if (location.pathname === "/stories") {
            setStory(false);
        } else {
            setStory(true);
        }
    }, [location.pathname, setStory]);

    const navigate = useNavigate();
    const menu = [
        { name: "Top Playlist", targetId: "top-playlist", path: "/top-playlist" },
        { name: "Moods", targetId: "mood", path: "/mood" },
        { name: "Stories", targetId: "stories", path: "/stories" },
        { name: "Guided Meditations", targetId: "guided-meditations", path: "/guided" },
        { name: "Brainwaves", targetId: "brainwaves", path: "/brainwaves" },
        { name: "Radio", targetId: "radio", path: "/radio" },
        { name: "Blogs", targetId: "blogs", path: "/blog" },
    ];

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(3px)',
    };

    return (
        <div className="">
            <div className="relative z-40">
                {image && (
                    <img
                        src={image}
                        className={`absolute ${location.pathname === "/" ? "opacity-40" : location.pathname === "/blog-des" ? "opacity-20" : "opacity-50"} z-20 ${location.pathname === "/blog-des" ? "h-[700px]" : "h-[325.5px]"} w-full`}
                        alt="background"
                    />
                )}

                <div className="relative px-5 flex justify-between z-40 items-center h-[67px]" style={customStyle}>
                    <img
                        src={logo}
                        alt="logo"
                        width={250}
                        className="cursor-pointer"
                        onClick={() => navigate('/')}
                    />

                    <div className="flex gap-12 text-[12.6px] font-semibold text-center">
                        {menu.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => navigate(item.path)}
                                className="cursor-pointer relative flex items-center"
                            >
                                {/* Background for the selected item */}
                                {location.pathname === item.path && (
                                    <div className="absolute inset-0 bg-white/30 z-[-1] w-full h-[67px] -mt-6"></div>
                                )}
                                <div className={`relative z-10 text-white px-3 ${location.pathname === item.path ? 'font-semibold' : ''}`}>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
