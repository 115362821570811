import React from 'react'
import VideoImage from "../../../assets/images/Group 7368.png"
const VideoSection = () => {
  return (
    <div className='pt-[300px] text-white '>
         <h1 className="text-center wix-madefor-text-bold font-bold text-[33.6px]">
                Sound solutions for your every Mood
            </h1>

        <div className='flex justify-center'>
            <img src={VideoImage} className='h-[478.1px] w-[1065.4px]'/>
            <iframe width="740" height="385" src="https://www.youtube.com/embed/F7_l28feM5A?si=TquimDwD0v4JKb90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen className='absolute mt-[54px]'></iframe>
        </div>
    </div>
  )
}

export default VideoSection