import React, { useState } from 'react';
import MobilePhone from "../../../assets/images/Rectangle (5).png";
import BackgroundImage1 from "../../../assets/images/photo1 (1).png";
import BackgroundImage2 from "../../../assets/images/photo2.png";
import HoverImage from "../../../assets/images/Rectangle (6).png";
import ExploreButton from './ExploreButton';
import { useNavigate } from 'react-router-dom';

const FifthSection = () => {
    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };
    const navigate = useNavigate();

    return (
        <div className=' text-white pt-[300px] relative bg-gradient-to-br from-black to-[#54568A] '>
            <div className='bg-container overflow-hidden absolute'>
                <div className="scroll-background-left">
                    <img src={BackgroundImage1} className='bg1' alt="Background 1" />
                    <img src={BackgroundImage1} className='bg1' alt="Background 1" />
                </div>
            </div>
            <div className='flex ml-20 mr-14 image-container  '>
        <img
          src={MobilePhone}
          className='w-[390px] h-[748px] default-image z-50 -mt-40 '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[390px] h-[748px] hovered-image z-50 -mt-40  '
          alt="Mobile Phone Hover"
        />
                <div className='z-20 ml-[280px] shadow-2xl shadow-black h-[303px] mt-20 rounded-lg py-14 px-24 text-center' style={customStyle}>
                    <h1 className='text-[33.6px] font-bold mb-[14px]'>Find Your Focus with Guided Meditations</h1>
                    <h3 className='mb-[35px] text-[17px] ml-12 mr-12'>Experience stress relief and enhance your focus with our expertly crafted guided meditations. Unlock a peaceful mind and a balanced life through the power of meditation.</h3>
                    
                        <ExploreButton path={'/guided'} />
                    
                </div>
            </div>
            <div className='bg-container overflow-hidden absolute -mt-8'>
                <div className="scroll-background-right">
                    <img src={BackgroundImage1} className='bg2' alt="Background 2" />
                    <img src={BackgroundImage1} className='bg2' alt="Background 2" />
                </div>
            </div>
        </div>
    );
};

export default FifthSection;
