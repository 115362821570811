import React from 'react'
import { useNavigate } from 'react-router-dom';

const ExploreButton = ({path}) => {
  const navigate=useNavigate();
  const handleExploreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
};

  return (
    <div onClick={()=>{
      handleExploreClick()
    }}>
         <button className="bg-white text-center text-black px-[44.1px] py-[7px] rounded-full font-extrabold text-[12.6px]">
                Explore now
            </button>
    </div>
  )
}

export default ExploreButton