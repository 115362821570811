import React from 'react';
import backgroundImage from "../../assets/images/BackgroundImageStory.png";
import Header from '../Header';
import story1 from "../../assets/images/Group 7354.png";
import story2 from "../../assets/images/Group 7355.png";
import story3 from "../../assets/images/Group 7382.png";
import story4 from "../../assets/images/Group 7385.png";
import story5 from "../../assets/images/Group 7386.png";
import story6 from "../../assets/images/Group 7384.png";
import Track from '../Track';
import DownloadLink from '../DownloadLink';
import Footer from '../Footer';

const Stories = () => {
    const width = "w-[372.4px] h-[269.5px]";
    const storyplaylist = [
        { storyName: story1 },
        { storyName: story2 },
        { storyName: story3 },
        { storyName: story4 },
        { storyName: story5 },
        { storyName: story6 }
    ];
    const text="All Stories"
    // Calculate the height dynamically based on the number of story items
    const itemHeight = 370; // approximate height of each Track (including margin)
    const height = storyplaylist.length * itemHeight; // total height

    return (
        <div className='relative'>
            {/* Apply dynamic height to background */}
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full z-10'
                style={{ minHeight: `${height}px` }} // Apply calculated height
            >
                <Header image={backgroundImage} />
            </div>

            {/* Absolute positioning for story items */}
            <div className='z-50 absolute top-0 left-0 mt-10 w-full'>
                {storyplaylist.map((i, index) => (
                    <Track key={index} image={i.storyName} width={width} />
                ))}
                
            </div>
            <Footer/>
        </div>
    );
};

export default Stories;
