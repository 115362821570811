import React, { useState } from 'react';
import BlogCard from '../Cards/BlogCard';
import LeftArrow from "../../../../assets/images/LeftArrow.png";
import RightArrow from "../../../../assets/images/RightArrow.png";
import { useNavigate } from 'react-router-dom';

const Slider = ({ items }) => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 4.25; 
    const itemWidthPercentage = 100 / itemsToShow;
    const totalItems = items.length;

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex >= totalItems) return totalItems - Math.ceil(itemsToShow);
            return newIndex;
        });
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex - 1;
            if (newIndex < 0) return 0;
            return newIndex;
        });
    };

    const getVisibleItems = () => {
        const end = currentIndex + Math.ceil(itemsToShow);
        return items.slice(currentIndex, end > totalItems ? totalItems : end);
    };

    const isAtStart = currentIndex <= 0;
    const isAtEnd = currentIndex + Math.ceil(itemsToShow) >= totalItems;

    return (
        <div className="flex flex-col">
            <div className="relative w-full overflow-hidden">
                <div
                    className="flex transition-transform duration-300"
                    style={{
                        transform: `translateX(-${currentIndex * itemWidthPercentage}%)`,
                        width: `${totalItems * itemWidthPercentage}%`
                    }}
                >
                    {getVisibleItems().map((item, index) => (
                        <div key={index} className="h-[520px] mt-10 ml-[50px] " style={{ width: `${itemWidthPercentage}%` }}>
                            <BlogCard
                                image={item.image}
                                des={item.des}
                                width={`${itemWidthPercentage}%`}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className=' justify-center flex'>
                <button
                    className={`bg-white text-center text-[22.4px] font-bold text-black py-[12.69px] px-[79.97px] mr-4 rounded-full cursor-pointer `}
                    onClick={() => { navigate('/blog') }}
                >
                    Read More
                </button>
            </div>
                <div className='flex justify-between absolute mt-[560px] right-0 mr-12'>
                <img
                    src={LeftArrow}
                    
                    className={`w-[53.2px] h-[53.2px] cursor-pointer ${isAtStart ? 'opacity-50 cursor-not-allowed' : ''}`}
                    alt="Previous"
                    style={{ opacity: isAtStart ? 0.5 : 1, cursor: isAtStart ? 'not-allowed' : 'pointer' }}
                    onClick={isAtStart ? null : prevSlide}
                />
                <img
                    src={RightArrow}
                    className={`w-[53.2px] h-[53.2px] cursor-pointer ${isAtEnd ? 'opacity-50 cursor-not-allowed' : ''}`}
                    alt="Next"
                    style={{ opacity: isAtEnd ? 0.5 : 1, cursor: isAtEnd ? 'not-allowed' : 'pointer' }}
                    onClick={isAtEnd ? null : nextSlide}
                />
                </div>
            
        </div>
    );
};

export default Slider;
