import React from 'react'

const ReviewCard = ({image}) => {
    const customStyle = {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.9)',
    };

    return (
        <div className='w-[900px] h-[130px] flex gap-3 text-white p-4 rounded-xl mr-10' style={customStyle}>
            <img src={image} className='w-[56.7px] h-[54.6px]' />
            <div className='mb-3'>
                <h1 className='text-[25px]'>
                    App is so good that I want to listen to songs every day...
                </h1>
                <h3 className='text-[12px]'>Riya Goyal</h3>
            </div>
        </div>
    );
}

export default ReviewCard;
