import React from 'react';
import backgroundImage from "../../assets/images/BackgroundDesign.png";
import Sleep from "../../assets/images/Group 7349.png";
import Fitness from "../../assets/images/Group 7350.png";
import Motivation from "../../assets/images/Motivation.png";
import Desk from "../../assets/images/Desk.png";
import Meditation from "../../assets/images/Medi.png";
import Deep from "../../assets/images/DeepFocus.png";
import Productivity from "../../assets/images/Productivity.png";
import Study from "../../assets/images/Study.png";
import Coding from "../../assets/images/Coding.png";
import Busy from "../../assets/images/Busy.png";
import Header from '../Header';
import Track from '../Track';
import DownloadLink from '../DownloadLink';
import Footer from '../Footer';

const TopPlaylist = () => {
    const text="Top Playlists"
    const width = "w-[372px] h-[269.5px]";
    const tracksplaylist = [
        { songName: Sleep },
        { songName: Fitness },
        { songName: Motivation },
        { songName: Meditation },
        { songName: Study },
        { songName: Desk }
    ];

    const itemHeight = 375;
    const height = tracksplaylist.length * itemHeight; 

    return (
        <div className='relative'>
            
            <div 
                className='bg-gradient-to-tr from-black to-[#342C43] w-full z-10 relative'
                style={{ minHeight: `${height}px` }} 
            >
                <Header image={backgroundImage} />
            </div>

            <div className='z-50 absolute top-0 left-0 mt-10 w-full'>
                {tracksplaylist.map((i, index) => (
                    <Track key={index} image={i.songName} width={width} />
                ))}
            
            </div>
            <Footer/>
        </div>
    );
};

export default TopPlaylist;
