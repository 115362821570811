import React from 'react'
import { useNavigate } from 'react-router-dom'

const BlogCard = ({image,des,width}) => {
  const handleExploreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/blog-des');
};

  const navigate=useNavigate();
  return (
    <div className='w-[562.1px] h-[450px] shadow-[#000000] shadow-2xl text-white p-4 rounded-xl cursor-pointer ' onClick={()=>{
      handleExploreClick();
    }}>
        <img src={image}  className='w-[555px] h-[268.1px]'/>
        <h3 className='text-[12.6px] font-semibold mt-3'>{des.category}</h3>
        <h1 className='text-[25.2px] font-bold mb-3'>{des.title}</h1>
        <h3 className='text-[12.6px] mb-3 font-bold'>{des.des}</h3>
        <h6 className='text-[#00A3EA] text-[12.6px] font-bold'>{des.pra}</h6>
    </div>
  )
}

export default BlogCard;