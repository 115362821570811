import React, { useEffect, useState, useRef } from 'react';
import Angry from "../../../assets/images/Angry.png";
import Distracted from "../../../assets/images/Distracted.png";
import Sad from "../../../assets/images/Sad.png";
import Ani from "../../../assets/images/Ani.png";
import Tired from "../../../assets/images/Frame 33623.png";
import Romantic from "../../../assets/images/Frame 33624.png";
import { useNavigate } from 'react-router-dom';

const ThirdSection = () => {
    const moods = [Angry, Distracted, Sad, Ani, Tired, Romantic];
    const [datamood, setDatamood] = useState([...moods, ...moods]);
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();

    const handleExploreClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/mood');
    };

    const addMoreImages = () => {
        setDatamood((prev) => [...prev, ...moods]); 
    };

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
            if (scrollLeft + clientWidth >= scrollWidth - 1) {
                addMoreImages();  
            }
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div className="text-white pt-[300px] opacity-100 bg-gradient-to-br from-black to-[#54568A]">
            <h1 className="text-center wix-madefor-text-bold font-bold text-[33.6px] mb-20">
                Sound solutions for your every Mood
            </h1>
            <div ref={scrollContainerRef} className="scroll-container overflow-x-auto">
                <div className="scroll-background-left-img flex">
                    {datamood.map((mood, index) => (
                        <img 
                            key={index}
                            src={mood} 
                            alt={`Mood ${index}`} 
                            onClick={() => handleExploreClick()}
                            style={{ width: '237.71px', height: '237.71px', marginRight: '89px' }}
                            className='cursor-pointer'
                        />
                    ))}
                </div>
            </div>
            <button 
                className="bg-white text-center text-black px-[87.17px] py-[13px] rounded-full mt-28 font-bold text-[24.90px] mx-auto cursor-pointer block"
                onClick={handleExploreClick}
            >
                Explore now
            </button>
        </div>
    );
};

export default ThirdSection;
