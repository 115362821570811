import React, { useState } from 'react';
import bg1 from "../../../assets/images/Section2Bg.png";
import bg2 from "../../../assets/images/Section2Bg2.png";
import MobilePhone from "../../../assets/images/MobileImage1.png";
import ExploreButton from './ExploreButton';
import HoverImage from "../../../assets/images/HoverImage1.png";

const SecondSection = () => {
const customStyle=  { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(3px)' }
  
  return (
    <div className='text-white relative '>
      <div className='bg-container overflow-hidden absolute mt-28'>
        <div className="scroll-background-left">
          <img src={bg1} className='bg1' alt="Background 1" />
          <img src={bg1} className='bg1' alt="Background 1" />
        </div>
      </div>

      <div className='flex ml-20 mr-14 image-container   '>
        <img
          src={MobilePhone}
          className='w-[390px] h-[748px] default-image z-50 -mt-10 '
          alt="Mobile Phone"
        />
        <img
          src={HoverImage}
          className='w-[390px] h-[748px] hovered-image z-50 -mt-10  '
          alt="Mobile Phone Hover"
        />
        <div className=' z-20 ml-72 shadow-2xl shadow-black h-[303px] rounded-lg px-10 py-10 text-center mt-[200px]' style={customStyle}>
          <h1 className='text-[33.6px] font-bold mb-[14px]'>Perfect Playlists for Every Need</h1>
          <h3 className='mb-[35px] text-[17px] mx-14'>
            Discover mind relaxing music, meditation tunes, and workout tracks. From motivational songs to focus music, our playlists enhance concentration and meet your every need.
          </h3>
          
            <ExploreButton path={'/top-playlist'}/>
          
        </div>
      </div>
      <div className='bg-container overflow-hidden absolute -mt-6 '>
        <div className="scroll-background-right">
          <img src={bg2} className='bg2' alt="Background 2" />
          <img src={bg2} className='bg2' alt="Background 2" />
        </div>
      </div>

    </div>
  );
}

export default SecondSection;
