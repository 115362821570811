import { useContext, useEffect, useState } from "react"
import React from 'react'
import { Howl } from "howler";
import { useRef } from "react";
import fire from "../assets/images/Fire.png"
import Strom from "../assets/images/Rain.png"
import Thunder from "../assets/images/Thunder.png"
import Waves from "../assets/images/waves.png"
import Wind from "../assets/images/Wind.png"
import Birds from "../assets/images/Birds.png"
import pauseIcon from "../assets/images/pause.png"
import minusIcon from "../assets/images/minussign.png"
import playIcon from "../assets/images/play.png"
import "./Track.css"
import { MyContext } from "../context/MyContext";
import { useLocation, useNavigate } from "react-router-dom";
const Track = ({image,width,song1,song2,song3,song4,download}) => {
    const {isStory,setStory}=useContext(MyContext);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioKey, setAudioKey] = useState(0);
    const [selectedEffects, setSelectedEffects] = useState([]);
    const [effectsSounds, setEffectsSounds] = useState([]);
    const audioRef = useRef(null);

    const [track1Progress, setTrack1Progress] = useState(0);
    const [track1CurrentTime, setTrack1CurrentTime] = useState("00:00");
    const [track1Duration, setTrack1Duration] = useState("00:00");

    const [track2Progress, setTrack2Progress] = useState(0);
    const [track2CurrentTime, setTrack2CurrentTime] = useState("00:00");
    const [track2Duration, setTrack2Duration] = useState("00:00");

    const [track3Progress, setTrack3Progress] = useState(0);
    const [track3CurrentTime, setTrack3CurrentTime] = useState("00:00");
    const [track3Duration, setTrack3Duration] = useState("00:00");

    const [track4Progress, setTrack4Progress] = useState(0);
    const [track4CurrentTime, setTrack4CurrentTime] = useState("00:00");
    const [track4Duration, setTrack4Duration] = useState("00:00");

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(3px)',
    };

    const location=useLocation();
    const handleSoundscapeClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share', '_blank');
    };

    useEffect(() => {
        if (currentTrack === null || !isPlaying) {
            effectsSounds.forEach(sound => sound.stop());
            setEffectsSounds([]);
            return;
        }

        effectsSounds.forEach(sound => sound.stop());

        const newEffectsSounds = selectedEffects.map(effectName => {
            const effectAudio = {
                Fire: "",
                Rain:"",
                Bird: "",
                Sun: "",
                Tree: "",
                Storm: "",
            }[effectName];

            return new Howl({
                src: [effectAudio],
                volume: 0.3,
                loop: true
            });
        });

        setEffectsSounds(newEffectsSounds);
        newEffectsSounds.forEach(sound => sound.play());

        return () => newEffectsSounds.forEach(sound => sound.stop());
    }, [selectedEffects, currentTrack, isPlaying]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current && audioRef.current.duration) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                const currentTime = formatTime(audioRef.current.currentTime);
                const duration = formatTime(audioRef.current.duration);

                if (currentTrack === 1) {
                    setTrack1Progress(progress);
                    setTrack1CurrentTime(currentTime);
                    setTrack1Duration(duration);
                } else if (currentTrack === 2) {
                    setTrack2Progress(progress);
                    setTrack2CurrentTime(currentTime);
                    setTrack2Duration(duration);
                }
                else if (currentTrack === 3) {
                    setTrack3Progress(progress);
                    setTrack3CurrentTime(currentTime);
                    setTrack3Duration(duration);
                }
                else if (currentTrack === 4) {
                    setTrack4Progress(progress);
                    setTrack4CurrentTime(currentTime);
                    setTrack4Duration(duration);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [audioRef, currentTrack, isPlaying]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const playTrack = (trackNumber) => {
        if (currentTrack === trackNumber) {
            if (isPlaying) {
                setIsPlaying(false);
                if (audioRef.current) {
                    audioRef.current.pause();
                }
            } else {
                setIsPlaying(true);
                if (audioRef.current) {
                    audioRef.current.play();
                }
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }

            setCurrentTrack(trackNumber);
            setIsPlaying(true);
            setAudioKey(prevKey => prevKey + 1); 
        }
    };

    const handleEffectClick = (effectName) => {
        setSelectedEffects(prevSelected => {
            if (prevSelected.includes(effectName)) {
                return prevSelected.filter(e => e !== effectName);
            }

            if (prevSelected.length >= 2) {
                alert("You can select at most 2 sound effects.");
                return prevSelected;
            }

            return [...prevSelected, effectName];
        });
    };

    const handleProgressChange = (event, trackNumber) => {
        const newTime = (event.target.value / 100) * audioRef.current.duration;
        audioRef.current.currentTime = newTime;

        if (trackNumber === 1) {
            setTrack1Progress(event.target.value);
        } else if (trackNumber === 2) {
            setTrack2Progress(event.target.value);
        }
        else if (trackNumber === 3) {
            setTrack3Progress(event.target.value);
        }
        else if (trackNumber === 4) {
            setTrack4Progress(event.target.value);
        }
    };
  return (
    <div className='mt-20 mx-16 text-white '>
<div className="flex gap-10">
    <img src={image} className={`${width}`}/>
<div>
    <div className="flex gap-6 mt-5 ">
                <div
                    className={`flex items-center p-3 py-5 h-16 rounded-2xl border border-gray-600 z-50 ${
                        currentTrack === 1 ? 'bg-white/30' : ''
                    }`}
                    style={customStyle}
                >
                    <button
                        onClick={() => playTrack(1)}
                        className="bg-transparent text-white focus:outline-none mr-4"
                    >
                        <img
                            src={isPlaying && currentTrack === 1 ? playIcon : pauseIcon}
                            width={40}
                            alt="Play/Pause"
                            className='cursor-pointer'
                        />
                    </button>
                    <div className='flex flex-col'>
                        <span className="text-white font-semibold text-base p-2">{isStory?"Track 1":"Episode 1"}</span>
                        {currentTrack === 1 && (
                            <div className="mb-4 flex flex-col text-sm">
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={track1Progress}
                                    onChange={(e) => handleProgressChange(e, 1)}
                                    className="w-full"
                                    style={{
                                        '--progress': `${track1Progress}%`
                                    }}
                                />
                                <div className="justify-between flex mt-1 mr-4 ml-2" >
                                <span className="text-white">{track1CurrentTime}</span>
                                
                                <span className="text-white">{track1Duration}</span>
                                    </div>

                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={`flex items-center p-3 py-5 rounded-2xl border border-gray-600 h-16 z-50 ${
                        currentTrack === 2 ? 'bg-white/30' : ''
                    }`}
                    style={customStyle}
                >
                    <button
                        onClick={() => playTrack(2)}
                        className="bg-transparent text-white focus:outline-none mr-4"
                    >
                        <img
                            src={isPlaying && currentTrack === 2 ? playIcon : pauseIcon}
                            width={40}
                            alt="Play/Pause"
                            className='cursor-pointer'
                        />
                    </button>
                    <div className='flex flex-col'>
                        <span className="text-white font-semibold text-base p-2">{isStory?"Track 2":"Episode 2"}</span>
                        {currentTrack === 2 && (
                            <div className="mb-4 flex flex-col text-sm">
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={track2Progress}
                                    onChange={(e) => handleProgressChange(e, 2)}
                                    className="w-full"
                                    style={{
                                        '--progress': `${track2Progress}%`
                                    }}
                                />
                                <div className="justify-between flex mt-1 mr-4 ml-2">

                                <span className="text-white">{track2CurrentTime}</span>
                                
                                <span className="text-white">{track2Duration}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`flex items-center p-3 py-5 rounded-2xl border border-gray-600 h-16 z-50 ${
                        currentTrack === 3 ? 'bg-white/30' : ''
                    }`}
                    style={customStyle}
                >
                    <button
                        onClick={() => playTrack(3)}
                        className="bg-transparent text-white focus:outline-none mr-4"
                    >
                        <img
                            src={isPlaying && currentTrack === 3 ? playIcon : pauseIcon}
                            width={40}
                            alt="Play/Pause"
                            className='cursor-pointer'
                        />
                    </button>
                    <div className='flex flex-col'>
                        <span className="text-white font-semibold text-base p-2">{isStory?"Track 3":"Episode 3"}</span>
                        {currentTrack === 3 && (
                            <div className="mb-4 flex flex-col text-sm">
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={track2Progress}
                                    onChange={(e) => handleProgressChange(e, 3)}
                                    className="w-full"
                                    style={{
                                        '--progress': `${track3Progress}%`
                                    }}
                                />
                                <div className="justify-between flex mt-1 mr-4 ml-2">

                                <span className="text-white">{track3CurrentTime}</span>
                                
                                <span className="text-white">{track3Duration}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`flex items-center p-3 py-5 rounded-2xl border border-gray-600 h-16 z-50 ${
                        currentTrack === 4 ? 'bg-white/30' : ''
                    }`}
                    style={customStyle}
                >
                    <button
                        onClick={() => playTrack(4)}
                        className="bg-transparent text-white focus:outline-none mr-4"
                    >
                        <img
                            src={isPlaying && currentTrack === 4 ? playIcon : pauseIcon}
                            width={40}
                            alt="Play/Pause"
                            className='cursor-pointer'
                        />
                    </button>
                    <div className='flex flex-col'>
                        <span className="text-white font-semibold text-base p-2">{isStory?"Track 4":"Episode 4"}</span>
                        {currentTrack === 4 && (
                            <div className="mb-4 flex flex-col text-sm">
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={track2Progress}
                                    onChange={(e) => handleProgressChange(e, 4)}
                                    className="w-full"
                                    style={{
                                        '--progress': `${track4Progress}%`
                                    }}
                                />
                                <div className="justify-between flex mt-1 mr-4 ml-2">

                                <span className="text-white">{track4CurrentTime}</span>
                                
                                <span className="text-white">{track4Duration}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <audio
                key={audioKey}
                src={currentTrack === 1 ? song1 : currentTrack===2?song2:currentTrack===3?song3:song4}
                autoPlay={isPlaying}
                ref={audioRef}
                onEnded={() => setIsPlaying(false)}
                hidden
            />
         {location.pathname==="/mood" ? <h3 className="mt-2 -mb-5 font-semibold text-[14px] text-white/60">Download <span className="underline text-[#008DCD] cursor-pointer" onClick={()=>{
        handleSoundscapeClick();
         }}>Soundscape</span> to access all tracks under Tired playlist</h3>:""}
            <div className="">
            <h4 className="text-white mb-4 font-bold mt-10">Try Adding Effects To The Above Track</h4>
            <div className="flex gap-10">
                {[
                    { name: 'Fire', image: fire },
                    { name: 'Rain', image: Thunder },
                    { name: 'Bird', image: Birds },
                    { name: 'Sun', image: Waves },
                    { name: 'Tree', image: Wind },
                    { name: 'Storm', image: Strom }
                ].map(effect => (
                    <div key={effect.name} className="relative text-center">
                        <img
                            src={selectedEffects.includes(effect.name) ? `${effect.image}?v=${new Date().getTime()}` : effect.image}
                            alt={effect.name}
                            className="w-16 h-16 rounded-md cursor-pointer z-50"
                            onClick={() => handleEffectClick(effect.name)}
                        />
                        {selectedEffects.includes(effect.name) && (
                            <img
                                src={minusIcon}
                                alt="Minus"
                                className="absolute top-1 right-2 w-4 h-4"
                                style={{ transform: 'translate(50%, -50%)' }}
                            />
                        )}
                        <p className="text-white mt-1">{effect.name}</p>
                    </div>
                ))}
            </div>
            </div>
                </div>
</div>

</div>
  )
}

export default Track